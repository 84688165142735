import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Grid, Stack, TextField, Typography } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCarTwoTone";
// import { debounce } from "lodash";
import moment from "moment";
import { useEditLenderMutation } from "../../../../store/slices/apiSlice";
import "./basicDetails.css";

const BasicDetailSection = React.memo(({ lender }) => { // setLender
  const lenderId = lender?._id;
  const [editLender] = useEditLenderMutation();

  const [logoUrlValue, setLogoUrlValue] = useState(lender?.logoURL);

  useEffect(() => {
    setLogoUrlValue(lender?.logoURL);
  }, [lender.logoURL]);

  const handleDateChange = (value) => {
    let date = "";

    if (value) {
      date = moment(value).format("DD MMM yyyy");
    }

    // setLender({
    //   ...lender,
    //   date,
    // });
    // debounce(() => {
      editLender({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
          date,
        },
      });
    // }, 1000)();
  };

  const handleLogoUrlChange = (value) => {
    // TODO: Add URL validation
    // setLender({
    //   ...lender,
    //   logoURL: value,
    // });
    // debounce(() => {
      editLender({
        lenderId,
        body: {
          ...Object.fromEntries(Object.entries(lender).filter(([key]) => key !== "_id" && key !== "productTiers")),
          logoURL: value,
        },
      });
    // }, 1000)();
  };

  return (
    <Grid
      container
      xs={12}
      style={{
        margin: "0 0 30px",
        padding: "0 0 20px",
        borderBottom: "1px solid rgba(0,0,0,0.12)",
      }}
    >
      <Grid container xs={3} alignItems="start" direction="column">
        <Stack direction="row" style={{ marginBottom: "10px" }}>
          <DirectionsCarIcon style={{ marginRight: "10px" }} />
          <Typography>Basic Details</Typography>
        </Stack>
      </Grid>
      <Grid
        container
        xs={9}
        spacing={2}
        style={{
          padding: "0 0 0 15px",
        }}
      >
        <Grid container spacing={1} xs={9} direction="row" style={{ margin: "0px 0px 7px 0px" }}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="Name"
              disabled
              variant="filled"
              type="text"
              name="lender"
              value={lender?.lender}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size="small"
              label="Application Type"
              disabled
              variant="filled"
              type="text"
              name="type"
              value={lender?.type?.charAt(0).toUpperCase() + lender?.type?.slice(1)}
            />
          </Grid>
          <Grid item xs={4}>
            <DatePicker
              selected={lender?.date}
              onChange={(date) => {
                if (date === null || moment(date).isValid()) {
                  handleDateChange(date);
                }
              }}
              dateFormat="d MMM yyyy"
              isClearable
              name="date"
              customInput={<TextField label="Date Updated" size="small" style={{ height: "100%" }} />}
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              onChange={(e) => setLogoUrlValue(e.target.value)}
              size="small"
              label="Logo URL"
              variant="filled"
              type="text"
              name="type"
              value={logoUrlValue || ""}
              onBlur={() => handleLogoUrlChange(logoUrlValue)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default BasicDetailSection;
